import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import { Button } from "react-bootstrap";
import { deleteCategorie, getCategories } from "../../reducers/commonReducer";
import CategoriesUpdate from "./CategoriesUpdate";
import CategoriesAdd from "./CategoriesAdd";
import { categories, home } from "../../const";

const Categories = () => {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const categId = queryParameters.get("catId");
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(
      categId
        ? getCategories({
            parent: categId,
          })
        : getCategories()
    );
    if (!localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
  }, [categId]);

  const { getCategoriesData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <Link to={categories}>
          <Button
            variant="success"
            className="m-2"
            onClick={() => {
              dispatch(getCategories());
            }}
          >
            All Categories
          </Button>
        </Link>
        <span className="m-2">
          {" "}
          <CategoriesAdd />{" "}
        </span>

        <table className="table table-scrolling ">
          <thead className="normalplace">
            <tr>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">OrderBY</th>
              {!categId && (
                <th className="over-col-size">View SubCategories</th>
              )}
              <th className="over-col-size">Image</th>
              <th className="over-col-size">Slug</th>
              <th className="over-col-size">Description</th>
              <th className="over-col-size">Status</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getCategoriesData?.list &&
              getCategoriesData?.list.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{data?.name}</td>
                    <td><b>{data?.orderby}</b></td>
                    {!categId && (
                      <td>
                        <Link
                          to={`${categories}?catId=${data?.id}`}
                          onClick={() => {
                            dispatch(
                              getCategories({
                                parent: data?.id,
                              })
                            );
                          }}
                        >
                          <Button variant="warning">View</Button>
                        </Link>
                      </td>
                    )}
                    <td>
                      <img className="tbl-img" src={data?.image} />
                    </td>
                    <td>{data?.slug}</td>
                    <td>{data?.description}</td>
                    <td>
                      <b>{data?.status === true ? "True" : "False"}</b>
                    </td>
                    <td>
                      <Button
                        className="m-1"
                        variant="danger"
                        onClick={async () => {
                          await dispatch(deleteCategorie(data?.id));
                          dispatch(getCategories());
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </Button>
                      <CategoriesUpdate
                        title="Update Categorie"
                        parent={data?.parent}
                        categorieName={data?.name}
                        categorieslug={data?.slug}
                        categoriedes={data?.description}
                        userStatus={data?.status}
                        id={data?.id}
                        catorderby={data?.orderby}
                        catImage={data?.image}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Categories;
